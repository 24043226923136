import React from 'react';
import "./plus-symbol.scss";
import crossClosed from "../../assets/img/cross-closed.svg";

function PlusSymbol() {
    return (
        <img src={crossClosed} className="PlusContainer"/>
    );
}

export default PlusSymbol;
