import React, {useEffect} from 'react';
import PolicyItem from "../../components/policy-item/policy-item";
import Legal from "../../components/legal/legal";
import Layout from "../../components/layout/layout";
import {connect} from "react-redux";
import {ToastContainer} from "react-toastify";

const LegalPrivacy = () => {

    return (
        <Layout>
            <ToastContainer/>
            <Legal title="AfriMed Privacy Policy">
                <PolicyItem
                    title="AfriMed Privacy Policy"
                    content="AfriMed is a Mobile Health Platform owned wholly by AfriMed Telehealth (PTY) Ltd, a Swiss based start-up. This privacy policy will explain how our organisation uses the personal data we collect from you when you use our website, mobile and web applications."
                />
                <PolicyItem
                    title="What data do we collect?"
                    content="<p>AfriMed collects the following data:</p>

<p>When you create an AfriMed ID, purchase a product, download a software update, connect to our services, contact us including by social media or participate in an online survey, we may collect a variety of information, including your name, mailing address, phone number, email address, contact preferences, device identifiers, IP address, location information, credit card information and profile information where the contact is via social media.</p>

<p>When you share your content with family and friends using AfriMed products, send gift certificates and products, or invite others to participate in AfriMed services or forums, AfriMed may collect the information you provide about those people such as name, mailing address, email address, and phone number. AfriMed will use such information to fulfil your requests, provide the relevant product or service, or for anti-fraud purposes.</p>

<p>In certain jurisdictions, we may ask for a government issued ID in limited circumstances including when setting up an account and activating your device, for the purpose of managing reservations, or as required by law.</p>

"/>
                <PolicyItem
                    title="How do we collect your data?"
                    content="<p>You directly provide AfriMed with most of the data we collect. We collect data and process data when you:</p>

<p>Register online or place an order for any of our products or services.
Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.
Use or view our website via your browser’s cookies.
Your social media engagement with any AfriMed’s social media pages.</p>

<p>AfriMed may also receive your data indirectly from the following sources:</p>

<p>We may have received your personal information from other persons if that person has shared their content with you using AfriMed products, sent gift certificates and products, or invited you to participate in AfriMed services or forums. We may also validate the information provided by you when creating an AfriMed ID with a third party for security and fraud prevention purposes.</p>

<p>If you are a potential candidate for employment with AfriMed, we may have received your personal information from third parties such as recruiters or external websites. We will use the personal information we receive to contact you about a potential opportunity or in evaluating your candidacy. If you did not provide us your personal information directly, we will inform you of the source when we first contact you regarding your candidacy. For research and development purposes, we may use datasets such as those that contain images, voices or other data that could be associated with an identifiable person. When acquiring such datasets, we do so in accordance with applicable law in the jurisdiction in which the dataset is hosted. When using such datasets for research and development, we do not attempt to re-identify individuals who may appear therein.</p>
"/>
                <PolicyItem
                    title="How will we use your data?"
                    updatePreferences={true}
                />
                <PolicyItem
                    title="How do we store your data?"
                    content="<p>AfriMed securely stores your data at:</p>
                <p style='padding-left:20px;'>
                Google Cloud Storage for Firebase<br>
                c/o Google Switzerland GmbH<br>
                Gustav-Gull-Platz 1<br>
                8004 Zürich<br>
                Switzerland<br>
                <br>
                support-ch@google.com<br>
                Telephone: +41 44 6681800<br>
                </p>
                <p>
                AfriMed will keep your [enter type of data] for [enter time period]. Once this time period has expired, we will delete your data by [enter how you delete users’ data].
                </p>
                "/>
                <PolicyItem
                    title="Marketing"
                    content="<p>AfriMed would like to send you information about our products and services that we think will be useful to you, as well as those of our partner companies.</p>

<p>[List organizations that will receive data]</p>

<p>If you have agreed to receive marketing, you may always opt out at a later date. You have the right at any time to stop AfriMed from contacting you for marketing purposes or giving your data to other members of the AfriMed Group.</p>

<p>If you no longer wish to be contacted for marketing purposes, please <a href='mailto:hello@afrimed.tech'>send us an email</a>.</p>"
                />

                <PolicyItem
                    title="What are your data protection rights?"
                    content="<p>AfriMed would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following.</p>

<p><b>The right to access</b> – You have the right to request AfriMed for copies of your personal data. We may charge you a small fee for this service.</p>

<p><b>The right to rectification</b> – it is in your legal right to request that AfriMed correct any information you believe is inaccurate. You also have the right to request AfriMed to complete the information you believe is incomplete.</p>

<p><b>The right to erasure</b> – it is in your legal right to request that AfriMed erase your personal data, under certain conditions.</p>

<p><b>The right to restrict processing</b> – it is in your legal right to request that AfriMed restrict the processing of your personal data, under certain conditions.</p>

<p><b>The right to object to processing</b> – it is in your legal right to object to AfriMed processing of your personal data, under certain conditions.</p>

<p><b>The right to data portability</b> – it is in your legal right to request that AfriMed transfer the data that we have collected to another organisation, or directly to you, under certain conditions.</p>

If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our legal email: <a href='mailto:hello@afrimed.tech' target='_blank'>hello@afrimed.tech</a>"
                />
                <PolicyItem
                    title="Cookies"
                    content="<p>Cookies are text files placed on your computer to collect standard Internet log information and visitor behaviour information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.</p>

                <p>For further general information about cookies, visit <a href='https://www.allaboutcookies.org/' target='_blank'>https://www.allaboutcookies.org/</a></p>"
                />

                <PolicyItem
                    title="How do we use cookies?"
                    content="<p>AfriMed’s websites, online services, interactive applications, email messages, and advertisements may use &quot;cookies&quot; and other technologies such as pixel tags and web beacons. These technologies help us better understand user behaviour, tell us which parts of our websites people have visited, and facilitate and measure the effectiveness of advertisements and web searches. We treat information collected by cookies and other technologies as non‑personal information. However, to the extent that Internet Protocol (IP) addresses or similar identifiers are considered personal information by local law, we also treat these identifiers as personal information. Similarly, to the extent that non-personal information is combined with personal information, we treat the combined information as personal information for the purposes of this Privacy Policy.</p>

<p>Ads that are delivered by AfriMed’s advertising platform may appear in AfriMed News and in App. If you do not wish to receive ads targeted to your interests from AfriMed's advertising platform, you can choose to enable Limit Ad Tracking, which will opt your AfriMed ID out of receiving such ads regardless of what device you are using. If you enable Limit Ad Tracking on your mobile device, third-party apps cannot use the Advertising Identifier, a non-personal device identifier, to serve you targeted ads. You may still see ads in the App or News based on context like your search query or the channel you are reading. In third-party apps, you may see ads based on other information.</p>

<p>AfriMed also uses cookies and other technologies to remember personal information when you use our website, online services, and applications. Our goal in these cases is to make your experience with AfriMed more convenient and personal. For example, knowing your first name lets us welcome you the next time you visit the AfriMed Online Store. Knowing your country and language − and if you are a doctor, your hospital or clinic − helps us provide a customised and more useful shopping experience. Knowing someone using your computer or device has shopped for a certain product or used a particular service helps us make our advertising and email communications more relevant to your interests. And knowing your contact information, hardware identifiers, and information about your computer or device helps us personalise your operating system, set up your AfriMed service, and provide you with better customer service.</p>

<p>If you want to disable cookies and you’re using a web browser, go to the browser preferences and then to the privacy pane to manage your preferences. On your AfriMed mobile device, go to Settings, then browser, scroll down to the Privacy & Security section, and tap on “Block Cookies” to manage your preferences. For more on your browsers, check with your provider to find out how to disable cookies. Please note that certain features of the AfriMed website will not be available once cookies are disabled.</p>

<p>As is true of most internet services, we gather some information automatically and store it in log files. This information includes Internet Protocol (IP) addresses, browser type and language, Internet service provider (ISP), referring and exit websites and applications, operating system, date/time stamp, and clickstream data. We use this information to understand and analyse trends, to administer the site, to learn about user behaviour on the site, to improve our product and services, and to gather demographic information about our user base as a whole. AfriMed may use this information in our marketing and advertising services.</p>

<p>In some of our email messages, we use a “click-through URL” linked to content on the AfriMed website. When customers click one of these URLs, they pass through a separate web server before arriving at the destination page on our website. We track this click-through data to help us determine interest in particular topics and measure the effectiveness of our customer communications. If you prefer not to be tracked in this way, you should not click text or graphic links in the email messages. Pixel tags enable us to send email messages in a format customer can read, and they tell us whether mail has been opened. We may use this information to reduce or eliminate messages sent to customers.</p>
                "/>

                <PolicyItem
                    title="What types of cookies do we use?"
                    content="<p>AfriMed websites and online services may use &quot;cookies&quot;. Cookies enable you to use shopping carts and to personalise your experience on our sites, tell us which parts of our websites people have visited, help us measure the effectiveness of ads and web searches, and give us insights into user behaviour so we can improve our communications and products.</p>

<p>If you want to disable cookies in your web browser, go to Preferences and then to the Privacy pane and choose to block cookies. On your iPad, iPhone, or iPod touch, go to Settings, then Safari, and then to the Cookies section. For other browsers, check with your provider to find out how to disable cookies.</p>

<p>Because cookies are used throughout our websites, disabling them may prevent you from using certain parts of the sites.</p>

<p>The cookies used on our websites have been categorized based on the guidelines found in the ICC UK Cookie guide. We use the following categories on our websites and other online services:</p>

<h3>Category 1 — Strictly Necessary Cookies</h3>

<p>These cookies are essential to enable you to browse around our websites and use their features. Without these cookies, services like shopping baskets and e-billing cannot be provided.</p>

<h3>Category 2 — Performance Cookies</h3>

<p>These cookies collect information about how you use our websites — for instance, which pages you go to most. This data may be used to help optimize our websites and make them easier for you to navigate. These cookies are also used to let affiliates know if you came to one of our websites from an affiliate and if your visit resulted in the use or purchase of a product or service from us, including details of the product or service purchased. These cookies don’t collect information that identifies you. All information these cookies collect is aggregated and therefore anonymous.</p>

<h3>Category 3 — Functionality Cookies</h3>

<p>These cookies allow our websites to remember choices you make while browsing. For instance, we may store your geographic location in a cookie to ensure that we show you our website localised for your area. We may also remember preferences such as text size, fonts, and other customisable site elements. They may also be used to keep track of what featured products or videos have been viewed to avoid repetition. The information these cookies collect will not personally identify you, and they cannot track your browsing activity on non-Apple websites.</p>

                "/>
                <PolicyItem
                    title="How to manage cookies"
                    content="<p>You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.</p>"
                />

                <PolicyItem
                    title="Privacy policies of other websites"
                    content="<p>The AfriMed website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>"
                />

                <PolicyItem
                    title="Changes to our privacy policy"
                    content="<p>AfriMed keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on November 30th 2021.</p>"
                />

                <PolicyItem
                    title="How to contact us"
                    content="<p>If you have any questions about AfriMed’s privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</p>

<p>Email us at:  <a href='mailto:hello@afrimed.tech' target='_blank'>hello@afrimed.tech</a></p>

<p>Or write to us at: AfriMed Customer Data, Schwabistalstrasse 27, 5037 Muhen, Switzerland</p>

<p>How to contact the appropriate authority</p>

<p>Should you wish to report a complaint or if you feel that AfriMed has not addressed your concern in a satisfactory manner, you may contact the Federal Data Protection and Information Commissioner (FDPIC)’s Office.</p>

<p style='padding-left:20px;'>
Department of Economics and Home Affairs<br>
Public and data protection officer<br>
Gunhilt Kersten<br>
Bahnhofplatz 13<br>
5201 Brugg<br>
<br>
Tel.: +41 62 835 45 60<br>
Fax: +41 62 835 45 59<br>
<a
href='https://www.ag.ch/de/dvi/ueber_uns_dvi/organisation_dvi/generalsekretariat/beauftragte_fuer_oeffentlichkeit_und_datenschutz/beauftragte_fuer_oeffentlichkeit_und_datenschutz.jsp'
target='_blank'
>
Homepage
</a>
</p>
"
                />

            </Legal>
        </Layout>
    )
}

export default LegalPrivacy;
