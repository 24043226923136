import React, {useState} from 'react';
import Header from "../../components/header/header";
import Contact from "../../components/contact/contact";
import Footer from "../footer/footer";
import "./legal.scss";
import Layout from "../layout/layout";

const Legal = ({children,title,subTitle}) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isInterested, setIsInterested] = useState(false);

    const toggle = (pInterested = false) => () => {
        setIsInterested(pInterested);
        setIsVisible(!isVisible);
    };

    return (
        <>
            {isVisible && <div className="ContactWrapper">
                <div className="Backdrop"/>
                <Contact toggle={toggle} isInterested={isInterested}/>
            </div>}
            <Header toggle={toggle}/>
            <div className="Container">
                <div className="Title">
                    {title}
                    {subTitle && <div className="SubTitle" dangerouslySetInnerHTML={{__html:subTitle}}>
                    </div>}
                </div>
                {children}
            </div>
            <Footer toggleContactUs={toggle} isLegalPage={true}/>
        </>
    )
}

export default Legal
