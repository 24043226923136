import React, {useState} from 'react';
import "./policy-item.scss";
import PlusSymbol from "../plus-symbol/plus-symbol";
import CrossSymbol from "../cross-symbol/cross-symbol";
import {connect} from "react-redux";

function PolicyItem({title, content, updatePreferences, showCookiesDialog, additionalContent}) {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    }

    return (
        <div className="ItemContainer">
            <div className="ItemHeader">
                <div className="ItemTitle">
                    {title}
                </div>
                {additionalContent &&
                <div className="AdditionalContent">
                    {additionalContent}
                </div>
                }
                {isCollapsed && <div className="ItemSymbol" onClick={toggleCollapse}>
                    <PlusSymbol/>
                </div>}
                {!isCollapsed && <div className="ItemSymbol" onClick={toggleCollapse}>
                    <CrossSymbol/>
                </div>}
            </div>
            {!updatePreferences && !isCollapsed && <div className="ItemContent">
                <div dangerouslySetInnerHTML={{__html: content}}></div>
            </div>}
            {updatePreferences && !isCollapsed && <div className="ItemContent">
                <p>We may process your personal information: for the purposes described in this Privacy Policy, with
                    your consent, for compliance with a legal obligation to which AfriMed is subject, for the
                    performance of a contract to which you are party, in order to protect your vital interests, or when
                    we have assessed it is necessary for the purposes of the legitimate interests pursued by AfriMed or
                    a third party to whom it may be necessary to disclose information. If you have questions about this
                    legal basis you can contact the Data Protection Officer.</p>
                <ul>
                    <li>
                        The personal information we collect allows us to keep you posted on AfriMed’s latest product
                        announcements, software updates, and upcoming events. If you don’t want to be on our mailing
                        list, you can opt-out anytime by <span className={"Link"} onClick={showCookiesDialog}>updating your preferences</span>.
                    </li>
                    <li>
                        We also use personal information to help us create, develop, operate, deliver, and improve our
                        products, services, content and advertising, and for loss prevention and anti-fraud purposes. We
                        may also use your personal information for account and network security purposes, including in
                        order to protect our services for the benefit of all our users, and pre-screening or scanning
                        uploaded content for potentially illegal content, including child sexual exploitation material.
                        Where we use your information for anti-fraud purposes it arises from the conduct of an online
                        transaction with us. We limit our uses of data for anti-fraud purposes to those which are
                        strictly necessary and within our assessed legitimate interests to protect our customers and our
                        services. For certain online transactions we may also validate the information provided by you
                        with publicly accessible sources.
                    </li>
                    <li>
                        We may use your personal information, including date of birth, to verify identity, assist with
                        identification of users, and to determine appropriate services. For example, we may use date of
                        birth to determine the age of AfriMed ID account holders.
                    </li>
                    <li>
                        From time to time, we may use your personal information to send important notices, such as
                        communications about purchases and changes to our terms, conditions, and policies. Because this
                        information is important to your interaction with AfriMed, you may not opt out of receiving
                        these communications.
                    </li>
                    <li>
                        We may also use personal information for internal purposes such as auditing, data analysis, and
                        research to improve AfriMed’s products, services, and customer communications.
                    </li>
                    <li>
                        If you enter into a sweepstake, contest, or similar promotion we may use the information you
                        provide to administer those programs.
                    </li>
                    <li>
                        If you apply for a position at AfriMed or we receive your information in connection with a
                        potential role at AfriMed, we may use your information to evaluate your candidacy and to contact
                        you. If you are a candidate, you will receive more information about how AfriMed handles
                        candidate personal information at the time of application.
                    </li>
                </ul>
                "

            </div>}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        cookiesDialogVisible: state.cookiesDialogVisible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showCookiesDialog: () => dispatch({type: `SHOW_DIALOG_COOKIES`}),
        hideCookiesDialog: () => dispatch({type: `HIDE_DIALOG_COOKIES`}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PolicyItem)

