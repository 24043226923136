import React from 'react';
import "../plus-symbol/plus-symbol.scss";
import crossOpen from "../../assets/img/cross-open.svg";

function CrossSymbol() {
    return (
        <img src={crossOpen} className="PlusContainer Rotated"/>
    );
}

export default CrossSymbol;
